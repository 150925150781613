import React, { Fragment, useEffect, useReducer, useState, useMemo } from 'react';
import { compose, withProps } from 'recompose';

import GraphQLCRUD from '../../../se/components/GraphQLCRUD';
import EntityEmptyState from '../../../se/components/entity-states/EntityEmptyState';

import get from 'lodash/get';
import { get as get2, set } from 'lodash/fp';

import { createdProcedureColumns, listColumns, uploadedProcedureColumns } from './columns';
import schema, {
  procedureStatistics,
  scheduledProceduresWithPhysicianSubscription,
  scheduledProcedureWithPhysician,
  procedureStatisticsWithPhysician,
} from '../../../graph/procedures';
import EntityView from '../../../se/components/entity/EntityView';
import QuestionnaireAnswers from './QuestionnaireAnswers';
import Filters from '../../pages/analytics/Filters';
import ProcedureEventLog, {
  filterNonIntakeEvents,
  procedureStatuseLabels,
  procedureStatuses,
} from './ProcedureEventLog';
import ProcedureInput from './ProcedureInput';
import { withTheme } from '../../../se/theme';
import ScheduledProcedureMobileListItem from './components/ScheduledProcedureMobileListItem';
import PatientVisits from './PatientVisits';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { SurgeryInstructions } from './instructions/SurgeryInstructions';
import { withSession } from '../../../state/Session';
import { unpackSessionObject } from '../../pages/unpackSessionObject';
import { RenderTitle } from './RenderTitle';
import { a11yProps, TabPanel } from './TabPanel';
import { DashboardFilter } from './DashboardFilter';
import useCareAppAccess from './utils/useCareAppAccess';
import Badge from '@material-ui/core/Badge';
import { NamedRange } from '../../core/DatePicker';
import scheduledProceduresSource from '../../../omnisearch/sources/hospital/scheduledProcedures';
import scheduledProceduresWithPhysicianSource from '../../../omnisearch/sources/hospital/scheduledProceduresWithPhysician';
import pick from 'lodash/fp/pick';
import Forms from './Forms';
import { useQuery } from '@apollo/client';
import { procedureForms } from '../../../graph/surgeon/forms';
import { DescriptionTwoTone } from '@material-ui/icons';
import ProcedureNotes from './ProcedureNotes';
import { getLastProcedureEvent } from '../../../util/procedureEvents';
import { ProcedureStatus } from '../../../types/ProcedureEvent';
import { FormType } from '../../../types/Form';
import FileUploaderPreOp from '../../FileUploaderPreOp';

import PatientFileGenerator from '../patient/views/PatientFileGenerator';
import { useScope } from '../../../hooks/useScope';
import { withScope } from '../../../contexts/ScopeContext';
import SelectionContextProvider from '../../../features/selection/SelectionContextProvider';
import { getSurgeryInstructionsDefaultTemplateType } from '../../../util/InstructionTemplatesService';
import identity from 'lodash/identity';

const Empty = withProps({
  title: `There are no scheduled procedures at the moment.`,
  hint: '',
  illustration: withTheme(theme => get(theme, 'illustration.patients')),
})(EntityEmptyState);

function tabSwitcher(state, action) {
  if (state.preopComplete) {
    switch (action.type) {
      case 'init':
        return { ...state, switchToTab: 1 };
      case 'formsExists':
        return state.formsLoaded
          ? state
          : action.formsExists
          ? { ...state, switchToTab: 2, formsLoaded: true }
          : { ...state, formsLoaded: true };
      default:
        return state;
    }
  } else {
    return state;
  }
}

const ProcedureAndQuestionnaire = props => {
  const { scope, accessToken, data, isPhysician } = props;
  useCareAppAccess(get(scope, 'hospital.id'), accessToken);
  const procedureId = data?.id;
  const notes = (data?.events || []).filter(event => event.type === 'Note');
  const notesCount = notes ? notes.length : 0;
  const procedureEvents = (data?.events || []).filter(event => event.type !== 'Note');
  const lastPreOpCompletedEvent = getLastProcedureEvent(data?.events || [], ProcedureStatus.PreOpCompleted);
  const questionnaireVersion = props?.data?.entryQuestionnaire?.questionnaireVersion;

  const {
    data: queryData,
    loading,
    error,
  } = useQuery(procedureForms, { variables: { procedureId, formType: FormType.PreOpForm } });
  const forms = (queryData?.procedureForms || []).filter(form =>
    form?.content?.includes('medicalPassport')
      ? (questionnaireVersion === 1 && form?.content === 'medicalPassport') ||
        `medicalPassport${questionnaireVersion}` === form?.content ||
        (form && form.content.startsWith('formsv2://'))
      : true
  );

  const formsExists = queryData?.procedureForms?.length > 0;

  const [tab, setTab] = useState(null);
  const tabNumber = tab || 0;

  const [{ switchToTab }, dispatch] = useReducer(tabSwitcher, {
    preopComplete: get(props, 'data.status') === procedureStatuses.preopCompleted,
  });

  useEffect(() => {
    dispatch({ type: 'init' });
  }, []);

  useEffect(() => {
    if (!loading && !error) {
      dispatch({ type: 'formsExists', formsExists });
    }
  }, [loading, error, formsExists]);

  useEffect(() => {
    if (switchToTab) {
      setTab(switchToTab);
    }
  }, [switchToTab]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const hospitalId = scope?.hospital?.id;

  const instructionData = get(props.data, 'procedureInstructions', '{}');
  const formType = JSON.parse(instructionData) || {};

  const [selectedTemplate, setSelectedTemplate] = useState(
    formType || getSurgeryInstructionsDefaultTemplateType(hospitalId)
  );

  return (
    <Fragment>
      <EntityView {...props} />
      {procedureId && <FileUploaderPreOp procedureId={procedureId} />}
      {formsExists && !isPhysician && (
        <Box mt={6} minWidth={0}>
          <Tabs
            indicatorColor="primary"
            value={tabNumber}
            onChange={handleChange}
            aria-label="PreOP Patient Tabs"
            variant="scrollable"
          >
            <Tab label="PreOp Questionnaire" {...a11yProps(0)} />
            <Tab label="Forms" {...a11yProps(1)} />
            <Tab label="Instructions" {...a11yProps(2)} />
            <Tab label="Patient Visits" {...a11yProps(3)} />
            <Tab
              label={
                <Badge invisible={notesCount < 1} badgeContent={notesCount} color="primary">
                  PreOp Notes&nbsp;&nbsp;
                </Badge>
              }
              {...a11yProps(4)}
            />
            <Tab label="Procedure Events" {...a11yProps(5)} />
          </Tabs>
          {/* {procedureId && <PatientFileGenerator procedureId={procedureId} />} */}
          <TabPanel value={tabNumber} index={0}>
            <QuestionnaireAnswers
              entryQuestionnaire={get(props, 'data.entryQuestionnaire')}
              pacuCharts={get(props, 'data.pacuCharts')}
              procedure={get(props, 'data', {})}
            />
          </TabPanel>
          <TabPanel value={tabNumber} index={1}>
            <Forms procedureId={procedureId} forms={forms} lastPreOpCompletedEvent={lastPreOpCompletedEvent} />
          </TabPanel>
          <TabPanel value={tabNumber} index={2}>
            <SurgeryInstructions
              data={get(props, 'data', {})}
              selectedTemplate={selectedTemplate}
              onSelectedTemplate={setSelectedTemplate}
            />
          </TabPanel>
          <TabPanel value={tabNumber} index={3}>
            <PatientVisits
              visits={props?.data?.visits}
              currentVisit={props?.data?.id}
              baseUrlProvider={props.baseUrlProvider}
            />
          </TabPanel>
          <TabPanel value={tabNumber} index={4}>
            <ProcedureNotes procedureId={procedureId} procedureNotes={notes} />
          </TabPanel>
          <TabPanel value={tabNumber} index={5}>
            <ProcedureEventLog events={procedureEvents} name="Procedure Events" filterEvents={filterNonIntakeEvents} />
          </TabPanel>
        </Box>
      )}
      {!formsExists &&  !isPhysician && (
        <Box mt={6}>
          <Tabs indicatorColor={'primary'} value={tabNumber} onChange={handleChange} aria-label="PerOp Patient Tabs">
            <Tab label="PreOp Questionnaire" {...a11yProps(0)} />
            <Tab label="Instructions" {...a11yProps(1)} />
            <Tab label="Patient Visits" {...a11yProps(2)} />
            <Tab
              label={
                <Badge invisible={notesCount < 1} badgeContent={notesCount} color="primary">
                  PreOp Notes&nbsp;&nbsp;
                </Badge>
              }
              {...a11yProps(3)}
            />
            <Tab label="Procedure Events" {...a11yProps(4)} />
          </Tabs>
          <TabPanel value={tabNumber} index={0}>
            <QuestionnaireAnswers
              entryQuestionnaire={get(props, 'data.entryQuestionnaire')}
              pacuCharts={get(props, 'data.pacuCharts')}
              procedure={get(props, 'data', {})}
            />
          </TabPanel>
          <TabPanel value={tabNumber} index={1}>
            <SurgeryInstructions
              data={get(props, 'data', {})}
              selectedTemplate={selectedTemplate}
              onSelectedTemplate={setSelectedTemplate}
            />
          </TabPanel>
          <TabPanel value={tabNumber} index={2}>
            <PatientVisits
              visits={get(props, 'data.visits')}
              currentVisit={get(props, 'data.id')}
              baseUrlProvider={props.baseUrlProvider}
            />
          </TabPanel>
          <TabPanel value={tabNumber} index={3}>
            <ProcedureNotes procedureId={procedureId} procedureNotes={notes} />
          </TabPanel>
          <TabPanel value={tabNumber} index={4}>
            <ProcedureEventLog events={procedureEvents} name="Procedure Events" filterEvents={filterNonIntakeEvents} />
          </TabPanel>
        </Box>
      )}
    </Fragment>
  );
};

const CustomFilter = withProps(props => ({
  textSearchKey: 'name',
  hideProcedureStatusSelectInput: false,
  hideCategorySelectInput: false,
  hideSpecialitySelectInput: !!props.physicianId,
  hideProcedureTypeSelectInput: true,
  hidePhysicianSelectInput: !!props.physicianId,
  futurePicker: true,
  defaultValue: {
    dateRange: NamedRange.next30Days(),
  },
  physicianId: props.physicianId,
}))(Filters);

const CustomDashboardFilter = withProps(props => ({
  subscription: props.physicianId ? procedureStatisticsWithPhysician(props.physicianId) : procedureStatistics,
  CustomFilter: filterProps => <CustomFilter {...filterProps} physicianId={props.physicianId} />,
  items: [
    {
      title: 'Procedures Scheduled',
      getter: data => get(data, 'procedureStatistics.scheduledProcedures', '-'),
      icon: <DescriptionTwoTone />,
    },
    {
      title: 'Patient Submitted',
      getter: data => get(data, 'procedureStatistics.totalForms', '-'),
      icon: <DescriptionTwoTone />,
    },
    {
      title: 'PreOp Complete',
      getter: data => get(data, 'procedureStatistics.preOpCompleted', '-'),
      icon: <DescriptionTwoTone />,
    },
    {
      title: 'Instructions Accepted',
      getter: data => get(data, 'procedureStatistics.instructionsAccepted', '-'),
      icon: <DescriptionTwoTone />,
    },
  ],
  physicianId: props.physicianId,
}))(DashboardFilter);

const ScheduledProceduresCRUD = (showColumns, physicianId) =>
  GraphQLCRUD({
    entityName: 'ScheduledProcedure',
    scheme: {
      ...schema,
      list: physicianId
        ? scheduledProceduresWithPhysicianSubscription(physicianId)
        : schema.scheduledProceduresSubscription,
      item: physicianId ? scheduledProcedureWithPhysician(physicianId) : schema.scheduledProcedure,
    },
    List: {
      tableKey: 'ScheduledProcedure',
      MobileItemComponent: ScheduledProcedureMobileListItem,
      useColumnSelection: true,
      columns: listColumns,
      Empty,
      FilterComponent: props => <CustomDashboardFilter {...props} physicianId={physicianId} />,
      FilterPrefix: 'sc',
      defaultFilterValues: {
        dateRange: NamedRange.next30Days(),
      },
      pickFilter: pick([
        'name',
        'physician',
        'procedureType',
        'hospital',
        'status',
        'dateRange',
        'category',
        'speciality',
        'view',
      ]),
    },
    Show: {
      columns: showColumns,
      View: compose(withSession(unpackSessionObject), withScope)(ProcedureAndQuestionnaire),
      Empty,
      Title: withProps({ procedureStatuses: procedureStatuseLabels })(RenderTitle),
    },
    ...(!physicianId && {
      Edit: {
        Input: ProcedureInput,
        skipRefetch: true,
        mapEditItemProps: ({ data }) => ({
          data: {
            ...data,
            scheduledProcedure: {
              ...data?.scheduledProcedure,
              patientName: data?.scheduledProcedure?.patientNameUnformatted,
              patientDateOfBirth: data?.scheduledProcedure?.patientDateOfBirthISO,
            },
          },
        }),
      },
    }),
    searchSource: physicianId ? scheduledProceduresWithPhysicianSource : scheduledProceduresSource,
  });

const ScheduledProcedures = props => {
  const scope = useScope();
  const createProcedureSchedule = scope?.hospital?.modules?.createProcedureSchedule;
  const organizationId = get2('hospital.id')(scope);
  const userMetadata = get2('session.user.metadata', props) || [];
  const memberships = get2('session.user.memberships', props) || [];
  const membership = memberships.find(m => m.organization.id === organizationId);
  const membershipMetadata = get2('metadata', membership) || [];
  const metadata = [...userMetadata, ...membershipMetadata];

  const physicianIdFromMetadata = useMemo(() => {
    const physicianMetadata = metadata.find(m => m.startsWith('Physician('));
    if (physicianMetadata) {
      const match = physicianMetadata.match(/Physician\((\d+)\)/);
      return match ? parseInt(match[1], 10) : null;
    }
    return null;
  }, [metadata]);

  const CRUDComponent = ScheduledProceduresCRUD(
    createProcedureSchedule ? createdProcedureColumns : uploadedProcedureColumns,
    physicianIdFromMetadata
  );

  return (
    <SelectionContextProvider>
      <CRUDComponent {...props} />
    </SelectionContextProvider>
  );
};

export default compose(withSession(identity), withScope)(ScheduledProcedures);
