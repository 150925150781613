import { ApolloClient, useSubscription } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { fragments } from '../../../graph/procedures';
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Source } from '../../../components/OmniSearch';
import * as H from 'history';
import { format } from 'date-fns';
import { AccessibilityNewRounded } from '@material-ui/icons';
import { Procedure } from '../../../types/Procedure';

interface Group {
  score: number;
  matches: string[];
}

function formatMultipleNotMatched(values: string[]) {
  const [last, ...rest] = values.reverse();
  return 'Not matching ' + rest.reverse().join(', ') + ' and ' + last;
}

const scheduledProceduresWithPhysicianSource: Source<Procedure & Group> = {
  name: 'Scheduled Procedure',
  useSource: (input: string, client: ApolloClient<any>, context: { [key: string]: any } = {}) => {
    const [index, setIndex] = useState(0);

    const [inputA, setInputA] = useState('');
    const [inputB, setInputB] = useState('');

    useEffect(() => () => setIndex(prev => prev + (1 % 2)), [input]);
    useEffect(() => (index === 0 ? setInputA : setInputB)(input), [input, index]);

    const subscriptionA = useSubscription<
      {
        searchScheduledProceduresWithPhysician?: {
          procedures: Procedure[];
          score: number;
          matches: string[];
        };
      },
      { search: string }
    >(
      gql`
        subscription searchScheduledProceduresA(
          $search: String!
          $physician: Long
          $speciality: String
          $dateRange: DateRange
          $status: String
          $physicianId: Long!
        ) {
          searchScheduledProceduresWithPhysician(
            search: $search
            physician: $physician
            speciality: $speciality
            dateRange: $dateRange
            status: $status
            physicianId: $physicianId
          ) {
            procedures {
              ...ProcedureData
            }
            score
            matches
          }
        }
        ${fragments.all}
      `,
      {
        client,
        variables: {
          ...context,
          search: inputA,
        },
      }
    );

    const subscriptionB = useSubscription<
      {
        searchScheduledProceduresWithPhysician?: {
          procedures: Procedure[];
          score: number;
          matches: string[];
        };
      },
      { search: string }
    >(
      gql`
        subscription searchScheduledProceduresB(
          $search: String!
          $physician: Long
          $speciality: String
          $dateRange: DateRange
          $status: String
          $physicianId: Long!
        ) {
          searchScheduledProceduresWithPhysician(
            search: $search
            physician: $physician
            speciality: $speciality
            dateRange: $dateRange
            status: $status
            physicianId: $physicianId
          ) {
            procedures {
              ...ProcedureData
            }
            score
            matches
          }
        }
        ${fragments.all}
      `,
      {
        client,
        variables: {
          ...context,
          search: inputB,
        },
      }
    );

    const [procedures, setProcedures] = useState<(Procedure & Group)[]>([]);

    useEffect(() => setProcedures([]), [input]);

    const { data, loading, error } = index === 0 ? subscriptionA : subscriptionB;

    useEffect(() => {
      const newProcedures: (Procedure & Group)[] = (data?.searchScheduledProceduresWithPhysician?.procedures || []).map(
        procedure => ({
          ...procedure,
          score: data!.searchScheduledProceduresWithPhysician!.score,
          matches: data!.searchScheduledProceduresWithPhysician!.matches,
        })
      );

      if (newProcedures.length > 0) {
        setProcedures(prev => [...prev, ...newProcedures]);
      }
    }, [data]);

    return {
      data: procedures,
      loading,
      error,
    };
  },
  groupBy: (option: Procedure & Group, context?: { [key: string]: any }) => {
    const shouldMatchPhysician = !!context?.physician;
    const shouldMatchSpeciality = !!context?.speciality;
    const shouldMatchDateRange = !!context?.dateRange;
    const shouldMatchStatus = !!context?.status;

    const didMatchPhysician = option.matches.includes('physician');
    const didMatchSpeciality = option.matches.includes('speciality');
    const didMatchDateRange = option.matches.includes('dateFrom') || option.matches.includes('dateTo');
    const didMatchStatus = option.matches.includes('procedureStatus');

    const notMatched: string[] = [
      shouldMatchDateRange && !didMatchDateRange ? 'Date Range' : null,
      shouldMatchPhysician && !didMatchPhysician ? 'Physician' : null,
      shouldMatchStatus && !didMatchStatus ? 'Status' : null,
      shouldMatchSpeciality && !didMatchSpeciality ? 'Speciality' : null,
    ].filter(_ => _) as string[];

    return notMatched.length === 0
      ? 'Top Results'
      : notMatched.length === 1
      ? 'Not matching ' + notMatched[0]
      : formatMultipleNotMatched(notMatched);
  },
  noOptionsText: (input: string) =>
    input.length > 0 ? 'No scheduled procedures found.' : 'Start typing to search scheduled procedures.',
  renderOption: (procedure: Procedure & Group, { selected: boolean }) => {
    const secondaryText = [
      procedure.startTime ? `DOS ${format(procedure.startTime, 'MM/DD/YYYY HH:mm')}` : 'No date of service',
      `Ph. ${procedure.physician?.name}` || 'No physician',
    ].join(' • ');

    return (
      <Box py={1} display="flex" flexDirection="column" style={{ width: 'fit-content' }}>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <AccessibilityNewRounded color="disabled" />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="baseline" justifyContent="flex-start">
            <Box display="flex" flexDirection="row" alignItems="baseline">
              <Typography>{procedure.patientName || '-'}</Typography>
              {!!procedure.isCanceled && (
                <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic', marginLeft: '0.5rem' }}>
                  Canceled
                </Typography>
              )}
            </Box>
            <Box display="flex" whiteSpace="nowrap">
              {procedure.patientDateOfBirth && (
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    DOB {procedure.patientDateOfBirth}
                  </Typography>
                </Box>
              )}
              <Box ml={1}>
                <Typography variant="body2" color="textSecondary">
                  Visit {procedure.visit}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" whiteSpace="nowrap">
              <Typography variant="body2">{secondaryText}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
  getOptionDisabled: (option: Procedure) => false,
  getOptionLabel: (option: Procedure) => option.patientName ?? '-',
  onSelect: (option: Procedure, location: H.Location, history: H.History) => {
    const prefix = (location.pathname.match(/\/su\/(\d+)/) ?? [''])[0];
    history.push(`${prefix}/scheduled-patients/${option.id}`);
  },
};

export default scheduledProceduresWithPhysicianSource;
